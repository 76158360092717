import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { UXService } from 'src/app/ux/services/ux.service';
import { INTEGRATION_PROVIDERS } from '../constants/integration-providers.constants';
import { Integration } from '../models/integration';
import { IntegrationProvider } from '../models/integration-provider';

@Injectable({ providedIn: 'root' })
export class IntegrationsService {
  constructor(
    private http: HttpClient,
    private ux: UXService,
  ) {}

  list() {
    return this.http
      .get<any>(`///integrations`)
      .pipe(map((list) => list.map((item: any) => new Integration(item))));
  }

  create(integration: Integration) {
    return this.http.post(`///integrations`, integration).pipe(
      tap({
        next: () => this.ux.notifier.success('¡Integración añadida!'),
        error: () => this.ux.notifier.error('¡Error al añadir la integración!'),
      }),
    );
  }

  update(integration: Integration, id: number | string) {
    return this.http.put(`///integrations/${id}`, integration).pipe(
      tap({
        next: () => this.ux.notifier.success('¡Actualizado correctamente!'),
        error: () => this.ux.notifier.error('¡No se pudo actualizar!'),
      }),
    );
  }

  delete(id: number | string) {
    return this.http.delete(`///integrations/${id}`);
  }

  providers(): IntegrationProvider[] {
    return INTEGRATION_PROVIDERS.map((item) => new IntegrationProvider(item));
  }
}
